<template>
  <div class="base-msg" v-loading="loading">
    <div class="base-content">
        <el-form class="medium-form" size="medium" :rules="rules" :model="formItem"
                 ref="postForm" label-position="right" label-width="90px">
          <p class="config-section-title">基础信息</p>
          <el-form-item label="用户名称：" prop="name">
            <el-input v-model="formItem.name" placeholder="请输入"></el-input>
          </el-form-item>
          <p class="config-section-title">班级信息</p>
          <el-form-item label="学段：" prop="period_id">
            <el-select v-model="formItem.period_id" placeholder="请选择" @change="getOptions(formItem)">
              <el-option v-for="item in selection.periodList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="毕业年份：" prop="entrance_year">
            <el-select style="margin-right: 28px" v-model="formItem.entrance_year" placeholder="请选择" @change="getOptions(formItem)">
              <el-option v-for="(item, index) in selection.yearList" :label="item.name" :key="index" :value="item.name"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="校区：" prop="campus_id">
            <el-select v-model="formItem.campus_id" placeholder="请选择" @change="getOptions(formItem)">
              <el-option v-for="item in selection.campusList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="班级：" prop="class_id">
            <el-select v-model="formItem.class_id" placeholder="请选择" :disabled="!selection.ClassList.length">
              <el-option v-for="item in selection.ClassList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
    </div>
    <FixedActionBar>
      <el-button @click="GoBack">返回</el-button>
      <el-button type="primary" @click="save">保存</el-button>
    </FixedActionBar>
  </div>
</template>

<script>
import SingleMediaWall from "@/modules/common/components/SingleMediaWall.vue";
import FixedActionBar from "@/base/layout/FixedActionBar";
import { addNoAuthStudent, ClassMembers } from "../../api/school-student-list";
import AddClassPlane from "../../components/Member/AddClassPlane.vue";
import {getStudentInfo, saveStudentInfo} from "@/modules/organization-admin/api/member/member-detail2";
import {searchClass} from "@/modules/organization-admin/api/member/class-message";
// import ClassList from "../../components/Member/ClassList.vue";

export default {
  data() {
    return {
      selection: {
        periodList: [],
        campusList: [],
        yearList: [],
        ClassList: [],
      },
      formItem: {
        id: '',
        name: '',
        campus_id: '',
        class_id: '',
        period_id: ''
      },
      rules: {
        name: [{ required: true, message: '请输入用户名称', trigger: "blur" }],
        class_id: [{ required: true, message: '请选择班级', trigger: "blur" }],
        period_id: [{ required: true, message: '请选择学段', trigger: "blur" }],
        campus_id: [{ required: true, message: '请选择校区', trigger: "blur" }],
        entrance_year: [{ required: true, message: '请选择毕业年份', trigger: "blur" }],
      },
      loading: true,
    };
  },
  methods: {
    GoBack() {
      this.$router.go(-1);
    },
    save() {
      this.$refs.postForm.validate(valid => {
        if (valid) {
          this.loading = true
          saveStudentInfo({
            id: this.$route.params.id,
            name: this.formItem.name,
            class_id: this.formItem.class_id
          }).then(res => {
            this.$message.success(res.msg)
            this.loading = false;
          }).catch(err => {
            this.loading = false;
          })
        }
      });
    },
    getOptions(query) {
      const {id, name, ...queryData} = query
      searchClass(queryData)
        .then((res) => {
          this.selection.periodList = res.data.period;
          this.selection.campusList = res.data.campus;
          this.selection.yearList = res.data.entrance_year;
          this.selection.ClassList = res.data.class;
          if (this.selection.ClassList.filter(el => el.id === this.formItem.class_id).length === 0) {
            this.formItem.class_id = ''
          }
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
        });
    }
  },
  created() {
    const uniqueId = this.$route.params.id
    getStudentInfo(uniqueId).then(res => {
      this.formItem = res.data
      this.getOptions(res.data)
    }).catch(() => {
    })
  },
  components: { SingleMediaWall, FixedActionBar, AddClassPlane },
};
</script>

<style lang="scss" scoped>
.base-msg {
  .title {
    padding-left: 8px;
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 16px;
    border-left: 3px #3479ef solid;
  }
}
.base-content {
  .avatar {
    font-size: 14px;
    margin: 40px 0 0 20px;
    display: flex;
    .content-title {
    }
  }
  .form {
    margin-top: 20px;
    .is-required {
      color: red;
    }
    .base-content {
      .avatar {
        font-size: 14px;
        margin: 40px 0 0 20px;
        display: flex;
        .content-title {
        }
      }
      .form {
        margin-top: 20px;
        .form-item {
          color: red;
        }
      }
    }
    .content {
      margin-top: 50px;
      p {
        margin-bottom: 20px;
      }
    }
  }
}
</style>
