import {api} from "@/base/utils/request";

export const getStudentInfo = (id) => {
  return api({
    url: '/admin/school/student/detail',
    method: 'post',
    data: {id}
  })
}

export const saveStudentInfo = (data) => {
  return api({
    url: '/admin/school/student/save',
    method: 'post',
    data
  })
}
